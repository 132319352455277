import React, { FC } from 'react'
import { graphql, PageProps } from 'gatsby'

import Work from '../components/Work'
import { GlobalPageContext } from '../types'
import { WorkQuery } from '../../graphql-types'
import PageContainer from '../components/PageContainer'
import createPageContainerProps from '../createPageContainerProps'

type Props = PageProps<WorkQuery, GlobalPageContext>

const WorkPage: FC<Props> = ({ data, pageContext }) => {
  const { seoTranslations, commonTranslations, workTranslations } = data
  const t = { ...seoTranslations!.json!, ...commonTranslations!.json!, ...workTranslations!.json! }

  return (
    <PageContainer
      translations={t}
      {...createPageContainerProps(data, pageContext)}
    >
      <Work />
    </PageContainer>
  )
}

export default WorkPage

export const pageQuery = graphql`
  query Work($pathRegex: String!, $lang: String!, $pageId: String!) {
    ...PageData

    workTranslations: file(
      name: { regex: $pathRegex }
      extension: { eq: "json" }
      absolutePath: { regex: "/translations/" }
    ) {
      json: childTranslationsJson {
        section {
          foosio {
            title
            content
            altLogo
            subtitle
            ctaLabel
            altPreview
          }
          tackl {
            content
            altLogo
            subtitle
            ctaLabel
            altPreview
          }
          abuli {
            content
            altLogo
            subtitle
            ctaLabel
            altPreview
          }
          fanta {
            content
            altLogo
            subtitle
            ctaLabel
            altPreview
          }
          tippstar {
            content
            altLogo
            subtitle
            ctaLabel
            altPreview
          }
          bild {
            content
            altLogo
            subtitle
            ctaLabel
            altPreview
          }
        }
      }
    }
  }
`
