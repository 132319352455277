import { Box } from 'rebass'
import styled from '@emotion/styled'

const GridContainer = styled(Box)`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto minmax(0, 1440px) auto;
`

export default GridContainer
