import React, { FC, ComponentProps as CP } from 'react'

import Img, { FluidObject } from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import { TippstarPreviewQuery } from '../../../../graphql-types'

const TippstarPreview: FC<Pick<CP<typeof Img>, 'alt'>> = ({ alt }) => {
  const { image }: TippstarPreviewQuery = useStaticQuery(graphql`
    query TippstarPreview {
      image: file(name: { eq: "tippstar_preview" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      alt={alt}
      fadeIn={false}
      loading="eager"
      fluid={image!.childImageSharp!.fluid as FluidObject}
    />
  )
}

export default TippstarPreview
