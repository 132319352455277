import React, { FC } from 'react'

import { Box } from 'rebass'

import useIntl from '../../states/useIntl'

import {
  BildLogo,
  AbuliLogo,
  FantaLogo,
  TacklLogo,
  FoosioLogo,
  TippstarLogo,
  BildPreview,
  AbuliPreview,
  FantaPreview,
  TacklPreview,
  FoosioPreview,
  TippstarPreview,
  LeftOrientedSection,
  RightOrientedSection,
} from './components'

const Work: FC = () => {
  const { t } = useIntl()

  return (
    <Box pb={[ 4, 4, 0 ]}>
      <LeftOrientedSection
        number={1}
        logoBackgroundColor="#2B2E52"
        title={t('section.foosio.title')}
        content={t('section.foosio.content')}
        ctaLabel={t('section.foosio.ctaLabel')}
        subtitle={t('section.foosio.subtitle')}
        logo={<FoosioLogo alt={t('section.foosio.altLogo')} />}
        preview={<FoosioPreview alt={t('section.foosio.altPreview')} />}
      />

      <RightOrientedSection
        number={2}
        logoBackgroundColor="#FEE001"
        content={t('section.tackl.content')}
        ctaLabel={t('section.tackl.ctaLabel')}
        subtitle={t('section.tackl.subtitle')}
        logo={<TacklLogo alt={t('section.tackl.altLogo')} />}
        preview={<TacklPreview alt={t('section.tackl.altPreview')} />}
      />

      <LeftOrientedSection
        number={3}
        logoBackgroundColor="#E20C1A"
        content={t('section.abuli.content')}
        ctaLabel={t('section.abuli.ctaLabel')}
        subtitle={t('section.abuli.subtitle')}
        logo={<AbuliLogo alt={t('section.abuli.altLogo')} />}
        preview={<AbuliPreview alt={t('section.abuli.altPreview')} />}
      />

      <RightOrientedSection
        number={4}
        logoBackgroundColor="#161D28"
        content={t('section.fanta.content')}
        ctaLabel={t('section.fanta.ctaLabel')}
        subtitle={t('section.fanta.subtitle')}
        logo={<FantaLogo alt={t('section.fanta.altLogo')} />}
        preview={<FantaPreview alt={t('section.fanta.altPreview')} />}
      />

      <LeftOrientedSection
        number={5}
        logoBackgroundColor="#FFCC00"
        content={t('section.tippstar.content')}
        ctaLabel={t('section.tippstar.ctaLabel')}
        subtitle={t('section.tippstar.subtitle')}
        logo={<TippstarLogo alt={t('section.tippstar.altLogo')} />}
        preview={<TippstarPreview alt={t('section.tippstar.altPreview')} />}
      />

      <RightOrientedSection
        number={6}
        logoBackgroundColor="#D91918"
        content={t('section.bild.content')}
        ctaLabel={t('section.bild.ctaLabel')}
        subtitle={t('section.bild.subtitle')}
        logo={<BildLogo alt={t('section.bild.altLogo')} />}
        preview={<BildPreview alt={t('section.bild.altPreview')} />}
      />
    </Box>
  )
}

export default Work
