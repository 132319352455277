import { Box } from 'rebass'
import styled from '@emotion/styled'

const VerticalDividerContainer = styled(Box)`
  float: right;
  height: 100%;
  width: fit-content;
  align-items: center;
  flex-direction: column;
`

export default VerticalDividerContainer
