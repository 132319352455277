import React, { FC } from 'react'

import styled from '@emotion/styled'
import { Box, Flex, Text } from 'rebass'
import { useTheme } from 'emotion-theming'
import { DefaultTheme } from 'styled-components'

import BorderBox from '../../BorderBox'
import { CompactCTAButton } from '../../CTAButton'

import GridContainer from './GridContainer'
import VerticalDivider from './VerticalDivider'

type Props = {
  title?: string
  number: number
  content: string
  subtitle: string
  ctaLabel: string
  logo: React.ReactNode
  preview: React.ReactNode
  logoBackgroundColor: string
}

const LeftImageContainer = styled(Box)`
  top: -25%;
  position: relative;
`

const LeftOrientedSection: FC<Props> = ({
  logo,
  title,
  number,
  preview,
  content,
  ctaLabel,
  subtitle,
  logoBackgroundColor,
}) => {
  const theme = useTheme<DefaultTheme>()

  return (
    <GridContainer
      as="section"
      pt={[ 0, title ? 0 : 6 ]}
    >
      <Box />

      <BorderBox
        ml={3}
        pr={3}
        borderBottom={`1px solid ${theme.colors.divider}`}
      >
        <Text
          my={4}
          as="h1"
          color="text"
          pb={[ 5, 5, 6 ]}
          fontSize={[ 36, 36, 72 ]}
        >
          {title}
        </Text>
      </BorderBox>

      <BorderBox
        borderBottom={`1px solid ${theme.colors.divider}`}
      >
        <VerticalDivider label={number} />
      </BorderBox>

      <Box />

      <Flex
        ml={3}
        pr={3}
        flexDirection={[ 'column', 'row' ]}
      >
        <Box flex={1}>
          <BorderBox
            mt={[ -55, -55, -90 ]}
            width={[ 110, 110, 180 ]}
            height={[ 110, 110, 180 ]}
            borderRadius={[ 25, 25, 50 ]}
            backgroundColor={logoBackgroundColor}
          >
            <Flex
              height="100%"
              px={[ 3, 3, 4 ]}
              flexDirection="column"
              justifyContent="center"
            >
              {logo}
            </Flex>
          </BorderBox>

          <Text
            as="h2"
            color="text"
            py={[ 3, 3, 4 ]}
            fontSize={[ 24, 24, 48 ]}
          >
            {subtitle}
          </Text>

          <Text
            as="p"
            color="text"
            fontSize={[ 14, 14, 18 ]}
          >
            {content}
          </Text>

          <CompactCTAButton
            as="div"
            containerProps={{
              my: [ 4, 4, 5 ],
              sx: { visibility: 'hidden' },
            }}
          >
            {ctaLabel}
          </CompactCTAButton>
        </Box>

        <BorderBox
          ml={[ 4, 4, 6 ]}
          mt={[ 0, -6, -7 ]}
          width={[ 240, 240, 450 ]}
          height={[ 400, 400, 750 ]}
          backgroundColor="secondary"
          borderRadius={[ 30, 30, 50 ]}
          alignSelf={[ 'flex-end', 'initial' ]}
        >
          <Flex
            height="100%"
            pr={[ 4, 4, 0 ]}
            pl={[ 0, 0, 5 ]}
            mr={[ 0, 0, -5 ]}
            ml={[ -4, -4, 0 ]}
            flexDirection="column"
            justifyContent="center"
          >
            {preview}
          </Flex>
        </BorderBox>
      </Flex>

      <Box>
        <VerticalDivider />
      </Box>
    </GridContainer>
  )
}

const RightOrientedSection: FC<Props> = ({
  logo,
  number,
  preview,
  content,
  ctaLabel,
  subtitle,
  logoBackgroundColor,
}) => {
  const theme = useTheme<DefaultTheme>()

  return (
    <Box as="section">
      <Flex>
        <Box flex={[ 0, 1 ]} />

        <BorderBox
          ml={3}
          flex={1}
          py={[ 5, 5, 6 ]}
          borderBottom={`1px solid ${theme.colors.divider}`}
        />

        <BorderBox
          borderBottom={`1px solid ${theme.colors.divider}`}
        >
          <VerticalDivider label={number} />
        </BorderBox>
      </Flex>

      <Flex
        flexDirection={[ 'column-reverse', 'row' ]}
      >
        <Box
          py={[ 5, 5, 0 ]}
          flex={[ 'initial', 1 ]}
        >
          <BorderBox
            pl={3}
            mr={[ 5, 5, 6 ]}
            mt={[ 0, 0, -90]}
            height={[ 220, 220, 400 ]}
            backgroundColor="secondary"
            borderTopRightRadius={[ 25, 25, 50 ]}
            borderBottomRightRadius={[ 25, 25, 50 ]}
          >
            <Box
              ml="auto"
              height="100%"
              maxWidth={[ 'initial', 'initial', 560 ]}
            >
              <LeftImageContainer
                maxWidth={[ 256, 256, 448 ]}
              >
                {preview}
              </LeftImageContainer>
            </Box>
          </BorderBox>
        </Box>

        <Box
          ml={3}
          pr={3}
          flex={1}
        >
          <Box
            maxWidth={[ 'initial', 'initial', 688 ]}
          >
            <BorderBox
              mt={[ -55, -55, -90 ]}
              width={[ 110, 110, 180 ]}
              height={[ 110, 110, 180 ]}
              borderRadius={[ 25, 25, 50 ]}
              backgroundColor={logoBackgroundColor}
            >
              <Flex
                height="100%"
                px={[ 3, 3, 4 ]}
                flexDirection="column"
                justifyContent="center"
              >
                {logo}
              </Flex>
            </BorderBox>

            <Text
              as="h2"
              color="text"
              py={[ 3, 3, 4 ]}
              fontSize={[ 24, 24, 48 ]}
            >
              {subtitle}
            </Text>

            <Text
              as="p"
              color="text"
              fontSize={[ 14, 14, 18 ]}
            >
              {content}
            </Text>

            <CompactCTAButton
              as="div"
              containerProps={{
                my: [ 4, 4, 5 ],
                sx: { visibility: 'hidden' },
              }}
            >
              {ctaLabel}
            </CompactCTAButton>
          </Box>
        </Box>

        <Box>
          <VerticalDivider />
        </Box>
      </Flex>
    </Box>
  )
}

export { LeftOrientedSection, RightOrientedSection }
