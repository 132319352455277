import React, { FC } from 'react'

import { Text } from 'rebass'
import { useTheme } from 'emotion-theming'
import { DefaultTheme } from 'styled-components'

import BorderBox from '../../BorderBox'
import VerticalDividerContainer from './VerticalDividerContainer'

type Props = {
  label?: string | number
}

const VerticalDivider: FC<Props> = ({ label }) => {
  const theme = useTheme<DefaultTheme>()

  return (
    <VerticalDividerContainer
      display={[ 'none', 'none', 'flex' ]}
    >
      <BorderBox
        mx={4}
        flex={1}
        borderLeft={`1px solid ${theme.colors.divider}`}
      />
      {
        label && (
          <Text
            py={3}
            color="text"
            fontWeight="bold"
          >
            {label}
          </Text>
        )
      }
    </VerticalDividerContainer>
  )
}

export default VerticalDivider
